import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionRightImage from 'components/section-right-image';

const TextArea = () => {
  const data = useStaticQuery(graphql`
    query innovationLabTextAreaQuery {
      json: philosophyJson(id: { eq: "philosophy-about" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      content: aboutUsTextJson {
        header {
          subtitle
          title
        }
        body
      }
    }
  `);
  const about = data.content;
  return (
    <SectionRightImage
      contents={{
        ...about.header,
        ...about,
      }}
      image={data.json.image}
      className={'top-left-full-shadow-corner'}
    />
  );
};

export default TextArea;
