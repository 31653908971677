import React from 'react';
import parse from 'html-react-parser';
import CountUp from 'react-countup';
import {
  CounterWrap,
  CounterCountRegular,
  CounterCountLarge,
  CounterTextRegular,
  CounterTextLarge,
} from './counter.style';

interface CounterProps {
  countTo: number;
  text: string;
  large?: boolean;
}

const Counter = ({ countTo, text, large }: CounterProps) => {
  let CounterCount = CounterCountRegular;
  let CounterText = CounterTextRegular;
  if (large) {
    CounterCount = CounterCountLarge;
    CounterText = CounterTextLarge;
  }

  return (
    <CounterWrap>
      {countTo && (
        <CounterCount>
          <CountUp end={countTo} duration={5} />
        </CounterCount>
      )}
      {text && <CounterText>{parse(text)}</CounterText>}
    </CounterWrap>
  );
};

export default Counter;
