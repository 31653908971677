import React from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import CountersArea from 'containers/innovation-lab/counters-area';
import TrendsArea from 'containers/innovation-lab/trends-area';
import { useStaticQuery, graphql } from 'gatsby';
import EnterpriseArea from 'containers/innovation-lab/enterprise-area';
import TextArea from 'containers/innovation-lab/text-area';
import bgAboutUs from 'assets/images/bg/headers/About-Us-Header.png';

const IndexInnovationLab = ({ location }) => {
  const { aboutUsHeroJson: data } = useStaticQuery(graphql`
    query aboutUsHeroDataQuery {
      aboutUsHeroJson {
        hero {
          title
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <SEO title="About Us" image={bgAboutUs} />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={data?.hero?.title}
          button={data?.hero?.button}
          hero="philosophy"
        />
        <CountersArea />
        <TextArea />
        <TrendsArea />
        <EnterpriseArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexInnovationLab;
