import styled from 'styled-components';
import { device } from 'theme';

export const CounterWrap = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-column-gap: 10px;
  align-items: center;

  @media ${device.large} {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }
`;

export const CounterCountRegular = styled.div`
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 70px;
  line-height: 1;
  text-align: right;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryAccent};
  @media ${device.medium} {
    text-align: center;
  }
`;

export const CounterCountLarge = styled(CounterCountRegular)`
  font-size: 180px;
  line-height: 0.8;
  text-align: left;
  @media ${device.small} {
    font-size: 120px;
  }
`;

export const CounterTextRegular = styled.h6`
  font-size: 18px;
  font-family: ${(props) => props.theme.fontFamily.h6};
  letter-spacing: 2px;
  color: ${(props) => props.theme.colors.black};
`;

export const CounterTextLarge = styled(CounterTextRegular)`
  font-size: 44px;
  text-transform: none;
  font-family: ${(props) => props.theme.fontFamily.heading};
  text-align: left;
  @media ${device.small} {
    font-size: 32px;
  }
`;
