import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Container, Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';
import { SectionWrap, ContainerWrap } from './trends-area.style';
import TrendCard from './trends-card';
import ReactMarkdown from 'react-markdown';
import { StyledComponentsBaseStylingProps } from 'theme';

type Card = {
  title: string;
  subtitle: string;
  body: string;
  image: ChildImage;
  link: string;
  buttonTitle: string;
};

type ChildImage = {
  childImageSharp: {
    fluid: FluidObject;
  };
};

const TrendsArea: FC<{ titleStyling: StyledComponentsBaseStylingProps }> = ({ titleStyling }) => {
  const data = useStaticQuery(graphql`
    query innovationLabTrendsQueryData {
      json: philosophyJson(id: { eq: "philosophy-trends" }) {
        cards {
          title
          image {
            childImageSharp {
              fixed(
                width: 60
                height: 60
                quality: 100
                webpQuality: 100
                fit: OUTSIDE
              ) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      content: aboutUsTrendsJson {
        cards {
          title
          subtitle
          body
          link
          buttonTitle
        }
        header {
          title
          subtitle
        }
        body
      }
    }
  `);
  let {
    cards,
    header: { title, subtitle },
    body,
  } = data.content;

  cards = cards.map((card: Card) => {
    const match = data.json.cards.find(
      (imageData: { title: string, image: any }) => imageData.title.toLowerCase() === card.title.toLowerCase(),
    );
    if (match) {
      // match icons to items in Strapi
      card.image = match.image;
    }
    return card;
  });

  return (
    <SectionWrap className="">
      <ContainerWrap className="left-red-slice md-no-bg-image">
        <Container>
          <Row>
            <Col lg={12} align="center">
              <Row className="mb-5">
                <Container>
                  <SectionTitle
                    title={title}
                    subtitle={subtitle}
                    {...titleStyling}
                  />
                  {body && <ReactMarkdown>{body}</ReactMarkdown>}
                </Container>
              </Row>
              <Row>
                {cards.map((card: Card, i: number) => (
                  <Col lg={6} md={6} className="box-item" key={`box-${i}`}>
                    <TrendCard
                      title={card.title}
                      subtitle={card.subtitle}
                      desc={card.body}
                      imageSrc={card.image && card.image.childImageSharp}
                      cta={card.buttonTitle}
                      path={card.link}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </ContainerWrap>
    </SectionWrap>
  );
};

TrendsArea.defaultProps = {
  titleStyling: {
    mb: '15px',
  },
};

export default TrendsArea;
