import styled from 'styled-components';
import { device } from 'theme';

export const SectionWrap = styled.section`
  padding-left: 40px;
  padding-right: 40px;

  padding-top: 120px;
  padding-bottom: 120px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 70px;
  }
`;

export const CountersWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-items: center;
  justify-content: center;
  @media ${device.large} {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    grid-auto-flow: row;
    grid-row-gap: 30px;
  }

  @media ${device.small} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
  }
`;
