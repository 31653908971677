import SectionTextContent from 'components/section-text-content';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Image from 'gatsby-image';
import { EnterpriseAreaWrapper } from './enterprise-area.style';

const EnterpriseArea = () => {
  const data = useStaticQuery(graphql`
    query philosophyPageEnterpriseAreaQuery {
      logo: logosJson(id: { eq: "atb_initials" }) {
        id
        image {
          childImageSharp {
            fixed(width: 90, height: 64, fit: INSIDE) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      content: aboutUsEnterpriseJson {
        body
        button {
          link
          title
        }
        header {
          subtitle
          title
        }
      }
    }
  `);
  return (
    <EnterpriseAreaWrapper className={'top-left-full-shadow-corner'}>
      {data?.logo?.image?.childImageSharp?.fixed && (
        <Image fixed={data.logo.image.childImageSharp.fixed} />
      )}
      <SectionTextContent {...data.content} />
    </EnterpriseAreaWrapper>
  );
};

export default EnterpriseArea;
