import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Counter from 'components/counter';
import { SectionWrap, CountersWrapper } from './counters-area.style';
import ReactMarkdown from 'react-markdown';
import Text, { TextStyle } from 'components/ui/text';

interface Props {
  textStyle?: TextStyle;
}

const CountersArea: FC<Props> = ({ textStyle }) => {
  const data = useStaticQuery(graphql`
    query aboutUsCountersDataQuery {
      json: aboutUsCountersJson {
        counters {
          count
          title
        }
        body
      }
    }
  `);

  if (!data?.json) {
    return null;
  }

  const { counters, body } = data.json;

  return (
    <SectionWrap>
      <CountersWrapper>
        {counters.map((item: { title: string; count: string }, i: number) => (
          <Counter
            key={`counter-${i}-${item.count}`}
            text={item.title}
            countTo={parseInt(item.count, 10)}
          />
        ))}
      </CountersWrapper>
      {body && (
        <Text {...textStyle}>
          <ReactMarkdown>{body}</ReactMarkdown>
        </Text>
      )}
    </SectionWrap>
  );
};

CountersArea.defaultProps = {
  textStyle: {
    as: 'h5',
    align: 'center',
    fontWeight: 400,
  },
};

export default CountersArea;
