import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  TrendsCardWrapper,
  TrendCardHeaderWrapper,
  TrendCardBodyWrapper,
} from './trends-area.style';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
import Subtitle from 'components/ui/subtitle';

interface Props {
  imageSrc: any;
  title?: string;
  subtitle?: string;
  desc?: string;
  cta?: string;
  path: string;
}

const TrendCard: FC<Props> = ({
  imageSrc,
  title,
  subtitle,
  desc,
  cta,
  path,
}) => {
  return (
    <TrendsCardWrapper>
      <TrendCardHeaderWrapper>
        {imageSrc && (
          <Img
            fixed={imageSrc.fixed}
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
        {title && <Heading as="h5">{title}</Heading>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TrendCardHeaderWrapper>
      <TrendCardBodyWrapper>
        {desc && <ReactMarkdown source={desc} />}
      </TrendCardBodyWrapper>
      {cta && (
        <Button to={path} varient="texted">
          {cta}
        </Button>
      )}
    </TrendsCardWrapper>
  );
};

TrendCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desc: PropTypes.string,
};

export default TrendCard;
