import styled from 'styled-components';
import { device } from 'theme';

export const SectionWrap = styled.section`
  padding-top: 100px;
  @media ${device.medium} {
    padding-top: 75px;
  }
  @media ${device.small} {
    padding-top: 54px;
  }
  .box-item {
    margin-bottom: 55px;
    @media ${device.small} {
      padding-top: 41px;
    }
  }
`;

export const ContainerWrap = styled.div`
  @media ${device.medium} {
    padding-left: 0;
  }
`;

export const TrendsCardWrapper = styled.article`
  box-shadow: ${(props) => props.theme.outlines.boxShadow};
  border-radius: ${(props) => props.theme.outlines.borderRadius};
  background: ${(props) => props.theme.colors.white};
  padding: 30px 45px;
  height: 100%;
`;

export const TrendCardHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & > h5 {
    margin: 15px 0;
  }
`;

export const TrendCardBodyWrapper = styled.div`
  margin: 30px 0;
`;
